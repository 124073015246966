<template>
  <div class="pt-12">
    <div
      class="w-full h-full bg-fixed bg-contain md:bg-center bg-no-repeat"
      :style="`background-image: url(${bgImage})`"
    >
      <div
        class="w-full py-12 px-4 md:px-0 flex items-center"
        style="height: 75vh"
      >
        <p
          class="w-full m-auto p-4 text-xl text-pulpBlue text-center font-semibold bg-softGrey80"
        >
          Get your students on the right path to English fluency with nexgen’s
          neo. <br />​ With courses for students of all ages and skill levels,
          neo provides a blended-learning environment that works for <br />
          both you and your students.​ <br /><br />
          <i>Keep scrolling to choose your institution.​</i>
        </p>
      </div>
      <div class="bg-white mb-48">
        <div
          class="md:w-768 lg:w-1024 mx-auto pt-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div>
            <h1 class="mb-4 text-2xl text-primary font-semibold">
              Placing Your Students at a Level That Is Right for Them
            </h1>
            <p class="text-base font-light text-textBlack text-justify">
              The first step in your students’ journey is getting placed in a
              level that precisely matches their current English CEFR level.
              neo’s adaptive Assessment Test will ensure they begin their
              personalized learning path in just the level and lesson that is
              right for them.​
            </p>
          </div>
        </div>
        <div
          class="md:w-768 lg:w-1024 mx-auto pb-12 px-4 md:px-0 flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full mt-10 text-left">
            <div class="flex flex-col md:flex-row items-start justify-between">
              <img
                rel="prefetch"
                class="md:h-52 lg:h-64"
                src="@/assets/images/icons/2.CERT-YOUNG-FEMALE.jpg"
                alt="2.CERT-YOUNG-FEMALE"
              />
              <div class="pt-4 md:pt-0 md:pl-10">
                <h3 class="text-2xl text-primary font-semibold">
                  Motivate Your Students with Internationally Accepted English Certificates
                </h3>
                <p
                  class="mt-4 text-base font-light text-textBlack text-justify"
                >
                  After all the hard work involved in completing a CEFR Level,
                  students will take a Certification test. If they pass the
                  test, they will be rewarded with certificates that are fully
                  aligned with the Common European Framework of Reference for
                  Languages (CEFR). The students' certificates can be easily
                  verified online using the certificate code on each
                  certificate. ​
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white mb-48">
        <div
          class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-textBlack text-left">
            <h1 class="mb-6 text-2xl text-primary font-semibold">
              A Comprehensive English Teaching Solution​
            </h1>
            <h3 class="text-xl">
              neo provides you with all the components you need for a complete
              blended-learning program: ​
            </h3>
            <ul class="flex flex-col md:flex-row text-textBlack">
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <h5 class="font-semibold text-pulpBlue text-center text-lg">
                  neo Navigator​
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  A video training course for teachers with certificates of
                  completion. The course helps you be the best you can be with
                  neo and frees up your time so you can help students.
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 md:mx-2 mt-6">
                <h5 class="font-semibold text-pulpBlue text-center text-lg">
                  Custom Grading Report​
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  Set your class expectations and goals to help motivate and
                  encourage effective study by customizing your own class
                  grading report.​​
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <h5 class="font-semibold text-pulpBlue text-center text-lg">
                  Easy Progress Tracking
                </h5>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  Monitor how effectively your students progress individually
                  and as a group. Class screen displays important information at
                  a glance to ensure overall progress towards identified goals.
                  The Student screen allows you to track and manage certificates
                  awarded.​
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div
          class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-white text-left">
            <ul class="flex flex-col md:flex-row text-textBlack">
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <div class="h-14 flex items-center justify-center">
                  <h5 class="font-semibold text-pulpBlue text-center text-lg">
                    Powerful Reporting ​ Features​
                  </h5>
                </div>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  Generate a variety of reports to easily track each student's
                  placement, progress, and achievements as they progress through
                  the certification level.​
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 md:mx-2 mt-6">
                <div class="h-14 flex items-center justify-center">
                  <h5 class="font-semibold text-pulpBlue text-center text-lg">
                    Recommended Class​ Activities (RCA)​
                  </h5>
                </div>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  Provide your students with in-class activities to extend and
                  personaize the language into a real-life context. Our RCAs
                  suggest fun and educational activities based on what your
                  students are studying.​
                </p>
              </li>
              <li class="md:w-4/12 bg-softGrey py-4 px-6 mt-6">
                <div class="h-14 flex items-center justify-center">
                  <h5 class="font-semibold text-pulpBlue text-center text-lg">
                    Workbooks and​ Answer Keys
                  </h5>
                </div>
                <p class="mt-2 pt-2 text-base border-t border-primary">
                  Reinforce mastery of the material—especially reading and
                  writing skills—with neo's workbooks. Fillable pdfs can be
                  downloaded or printed for each level of all three courses,
                  along with their answer keys.​
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="bg-white">
        <div
          class="md:w-768 lg:w-1024 mx-auto py-12 px-4 md:px-0 border-t flex flex-col-reverse md:flex-row justify-center"
        >
          <div class="w-full text-textBlack text-left">
            <h1 class="mb-6 text-2xl text-center text-primary font-semibold">
              Select the institution you currently teach at:​
            </h1>
            <ul class="flex flex-wrap justify-evenly">
              <li
                class="bg-softBlue py-4 px-6 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
              >
                <router-link class="font-semibold text-lg" to="/preschool">
                  Preschool​
                </router-link>
              </li>
              <li
                class="bg-softBlue py-4 px-6 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
              >
                <router-link
                  class="font-semibold text-lg"
                  to="/elementaryschool"
                >
                  Elementary School
                </router-link>
              </li>
              <li
                class="bg-softBlue py-4 px-6 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
              >
                <router-link class="font-semibold text-lg" to="/middleschool">
                  Middle School​
                </router-link>
              </li>
              <li
                class="bg-softBlue py-4 px-6 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
              >
                <router-link class="font-semibold text-lg" to="/highschool">
                  High School​
                </router-link>
              </li>
              <li
                class="bg-softBlue py-4 px-6 mt-6 text-pulpBlue hover:bg-secondary hover:text-white transition-all"
              >
                <router-link class="font-semibold text-lg" to="/higherlearning">
                  Higher Learning ​
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LottieAnimationVue from "../components/lottie/LottieAnimation.vue";

export default {
  components: {
    LottieAnimationVue,
  },
  data() {
    return {
      levels: [
        "Reading Exercises ​",
        "Sentence Focus ​",
        "Vocabulary Exercises ​",
        "Dictations ​",
        "Grammar Focus ​",
        "Memory Games​",
      ],
      isMobile: window.innerWidth <= 768, // Adjust the breakpoint as needed
      mobileImageUrl: require("@/assets/images/mobile/1.-teacher-720x1280.jpg"),
      desktopImageUrl: require("@/assets/images/icons/1.NEWteacher-section-image.jpg"),
    };
  },
  computed: {
    bgImage() {
      return this.isMobile ? this.mobileImageUrl : this.desktopImageUrl;
    },
  },
};
</script>

<style>
</style>